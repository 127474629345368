import React from "react"
import { Link, graphql } from "gatsby"
import * as Icon from "react-feather"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import NavbarNew from "../../components/NavbarNew"
import Footer from "../../components/_App/Footer"
import PageBanner from "../../components/Common/PageBanner"
import GetInTouch from "../../components/get-in-touch"

// import webAppIconBig from "../../assets/images/web-app-service-icon-big.png"
import webAppIconBig from "./web-app-service.png"
import engagementBilling from "./engagement-billing.png"

import Reactjs from "../../assets/images/reactjs.svg"
import Angular from "../../assets/images/angular-logo.webp"
import Gatsby from "../../assets/images/Gatsby-Monogram.svg"
import CaseStudyList from "../../components/case-study-list"
import Testimonial from "../../components/testimonial"
import RecipeList from "../../components/recipe-list"
import { MDXRenderer } from "gatsby-plugin-mdx"
import link from "../../assets/images/link.png"

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))
const options = {
  loop: true,
  nav: true,
  dots: true,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 7000,
  items: 1,
  navClass: ["owl-pre-icon", "owl-next-icon"],
  navText: ["", ""],
}
const awardsCarouselOptions = {
  loop: true,
  nav: true,
  dots: true,
  autoplay: true,
  smartSpeed: 1000,
  autoplayTimeout: 5000,
  items: 1,
  margin: 30,
  navClass: ["owl-pre-icon", "owl-next-icon"],
  navText: ["", ""],
}
const ServiceDetails = ({ data }) => {
  const [display, setDisplay] = React.useState(false)
  // console.log(data)

  const recipeFeatures = data?.ourRecipeFeatures?.nodes
  // console.log("recipeFeatures", recipeFeatures)

  const caseStudyList = data?.caseStudies?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )

  const featuredCaseStudy =
    data.featuredCaseStudy.siteMetadata.webFeaturedCaseStudies
  const filteredCaseStudyList = featuredCaseStudy.reduce((acc, cur) => {
    const studyListItem = caseStudyList.find(
      item => item.relativeDirectory === cur
    )
    if (studyListItem) {
      acc.push(studyListItem)
    }
    return acc
  }, [])
  console.log("filteredCaseStudyList", filteredCaseStudyList)
  // console.log("caseStudyList", caseStudyList)

  const testimonials = data?.testimonials?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )
  // console.log("testimonials", testimonials)

  const awards = data?.awards?.nodes.filter(
    node => node.relativeDirectory.search("/") === -1
  )
  // console.log("awards", awards)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <Layout>
      <SEO
        title="Tarkashilpa -Web Application Development Services Company"
        description="We are web application development Services company in India,Tarkashilpa offers professional and affordable world class web solutions to clients acroos the globe."
        keywords="web application development, web application development company, web application development services, web app development company"
        // url={`${window.location.origin}/services/web-app-development/`}
        url={`/services/web-app-development/`}
        ogTwitterCardType="summary"
      />

      <NavbarNew />

      <PageBanner pageTitle="Web  App Development" />

      <div className="services-details-area ptb-80">
        <div className="container">
          <div className="row align-items-center align-items-md-start">
            <div className="col-lg-6 col-md-6 services-details-image web-app">
              <img src={webAppIconBig} alt="Web App Development" />
            </div>
            <div className="col-lg-6 col-md-6 services-details">
              <div className="services-details-desc">
                {/* <h3>Incredible Infrastructure</h3> */}
                <p>
                  {" "}
                  A web-based solution can automate your business processes and
                  help your team to work efficiently. It brings effective
                  collaboration and let your team work in synergy. It enables
                  easy tracking of your business KRA and drives powerful
                  insights from the business data. A well-designed solution
                  would boost your brand’s engagement with your customers and
                  can drastically improve the conversion rate.{" "}
                </p>

                <p>
                  We have a <a href="#our-recipe">recipe</a> to build a perfect
                  web-based solution that helps you achieve your business goals
                  for given business parameters. It requires a thorough
                  understanding of the business challenges we are solving and
                  undiverted attention to business KRAs at every stage of
                  building to tailor a perfect solution for you.{" "}
                </p>

                <p>
                  We measure the success of the solution delivered based on how
                  well it solves your business challenges and we had delivered
                  success to a number of businesses to date. Explore our web app
                  case studies <a href="#case-studies">here </a>. It fills us
                  with pride when our clients loved our work; discover what our
                  clients are saying about our work{" "}
                  <a href="#testimonials">here</a>.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our-recipe pt-80 pb-50 bg-f9f6f6" id="our-recipe">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#our-recipe">
                  <img src={link} alt="" />
                </a>
                Our Recipe
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              Our recipe to deliver winning apps is the perfect blend of
              processes, practices, and efficient tools combined with people of
              the right skill set. We groom our team with a favorable culture
              that encourages them to understand fundamentals and appreciate the
              value they provide to businesses. Below mentioned are the guiding
              principles of our recipe. Our full recipe is available{" "}
              <a href="/our-recipe">here</a>
            </p>
          </div>
          {recipeFeatures &&
            recipeFeatures.length > 0 &&
            recipeFeatures.map((list, index) => {
              const type = index % 2 === 0 ? "odd" : "even"
              // console.log("type", type)
              return (
                <RecipeList
                  title={list.childMdx.frontmatter.title}
                  content={<MDXRenderer>{list?.childMdx?.body}</MDXRenderer>}
                  thumb={list.childMdx.frontmatter.thumbImg.publicURL}
                  type={type}
                />
              )
            })}
        </div>
        <div className="text-align-center">
          <a className="btn btn-primary" href="/our-recipe">
            Learn More
          </a>
        </div>
      </div>

      <div className="iot-why-choose-us pt-80" id="tech-stack">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#tech-stack">
                  <img src={link} alt="" />
                </a>
                Tech Stack
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              We build using the modern web technologies that gives best of
              flexibility, scalability and security.
            </p>
          </div>

          <div className="row justify-content-center tech-stack-gap">
            <div className="col-lg-4 col-md-9">
              <div className="single-iot-box">
                <div className="icon">
                  <a
                    href="https://reactjs.org"
                    target="_blank"
                    rel="nofollow"
                    className="tech-logo"
                  >
                    <img src={Reactjs} alt="image" />
                  </a>
                </div>
                <h3>Reactjs</h3>
                <p>
                  React is our first choice of technology while building dynamic
                  web apps because of the freedom it provide to architect our
                  apps that suits best to project requirements. We can choose a
                  highly scalable architecture for very large and complex apps
                  or choose a simple architecture for the basic app to keep it
                  easy. We use Redux / Mobx as the state management companion
                  with React.
                </p>
                <Link to="https://reactjs.org" target="_blank" rel="nofollow">
                  <Icon.ArrowRight />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-9">
              <div className="single-iot-box">
                <div className="icon">
                  <a
                    href="https://angular.io"
                    target="_blank"
                    rel="nofollow"
                    className="tech-logo"
                  >
                    <img src={Angular} alt="image" />
                  </a>
                </div>
                <h3>Angular</h3>
                <p>
                  Angular is a modern and component-based framework that we
                  could use while building dynamic web apps. Angular helps in
                  keeping our architecture consistent and structured and helps
                  us build professional-grade web apps groud up quickly.
                </p>
                <Link to="https://angular.io" target="_blank" rel="nofollow">
                  <Icon.ArrowRight />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-9">
              <div className="single-iot-box">
                <div className="icon ">
                  <a
                    href="https://www.gatsbyjs.com"
                    target="_blank"
                    rel="nofollow"
                    className="tech-logo"
                  >
                    <img src={Gatsby} alt="image" />
                  </a>
                </div>
                <h3>GatsbyJs</h3>
                <p>
                  Gatsbyjs is our choice of technology while building a content
                  based websites. Gatsby has ready transformers and connectors
                  which could let us connect our website with CMS of our choice.
                  The site build using Gatsby is blazingly fast as most of the
                  content is rendered during build time.
                </p>
                <Link
                  to="https://www.gatsbyjs.com"
                  target="_blank"
                  rel="nofollow"
                >
                  <Icon.ArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="case-studies ptb-80" id="case-studies">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#case-studies">
                  <img src={link} alt="" />
                </a>
                Case Studies
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              We have delivered the success to businesses who are market leaders
              of their industries through our time tested methodologies and
              values. These case studies demonstrate the effectivity{" "}
              <a href="#our-recipe">our recipe</a> and capabilities.
            </p>
          </div>
          <div className="row justify-content-center">
            {filteredCaseStudyList && filteredCaseStudyList.length > 0 && (
              <div className="row justify-content-center">
                <div className="d-flex flex-md-row flex-column justify-content-center gap-3">
                  {filteredCaseStudyList.map((list, index) => (
                    <div
                      className="flex-1 single-solutions-box"
                      key={index + 1}
                    >
                      <CaseStudyList data={list?.childMdx} />
                    </div>
                  ))}
                </div>
                <div className="text-align-center">
                  <a className="btn btn-primary" href="/our-work">
                    View More
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="bigdata-feedback-area pt-80 pb-50" id="testimonials">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#testimonials">
                  <img src={link} alt="" />
                </a>
                Testimonials
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              There is a reason why we are recognized as top developers, we
              deliver...
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {display ? (
                <OwlCarousel className="owl-carousel owl-theme" {...options}>
                  {testimonials &&
                    testimonials.length > 0 &&
                    testimonials.map(list => (
                      <Testimonial data={list?.childMdx?.frontmatter} />
                    ))}
                </OwlCarousel>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="awards-recognition pt-80 pb-50" id="awards-recognition">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link">
              <span>
                <a href="#awards-recognition">
                  <img src={link} alt="" />
                </a>
                Awards and Recognition
              </span>
            </h2>
            <div className="bar"></div>
            <p>We are proud to be recognized for quality of our work</p>
          </div>
          <div className="awards-screenshots pb-80">
            <OwlCarousel
              className="owl-carousel owl-theme"
              {...awardsCarouselOptions}
            >
              {awards &&
                awards.length > 0 &&
                awards.map(list => (
                  <div className="awards-wrapper">
                    <h3>{list?.childMdx?.frontmatter.title}</h3>
                    <div className="img-wrapper">
                      <img
                        src={list?.childMdx?.frontmatter.preview?.publicURL}
                        alt={list?.childMdx?.frontmatter.name}
                      />
                    </div>
                  </div>
                ))}
            </OwlCarousel>
            {/* <div className="clutch-badge" style={{ width: 200 }}>
              <a
                href="https://clutch.co/profile/tarkashilpa-technologie"
                target={"_blank"}
              >
                <img
                  src={b2bCompaniesBadge}
                  className="animate__animated animate__fadeInDown animate__delay-0.2s"
                  alt="B2B Companies Badge"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>

      <div
        className="services-area ptb-80 bg-f7fafd"
        id="engagement-and-billing-model"
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 services-content">
              <div className="section-title">
                <h2 className="hover-link">
                  <span>
                    <a href="#engagement-and-billing-model">
                      <img src={link} alt="" />
                    </a>
                    Engagement and Billing Model
                  </span>
                </h2>
                <div className="bar"></div>
              </div>
              <p>
                We believe in setting the expectations upfront and keep the
                pricing transparent. We also believe in educating our clients if
                necessary with the pros and cons of different engagement,
                delivery and billing models so they can take a informed
                decision. The choice of right models depends on various factors
                and we love to work with you to help you choose the right one
                based on your needs.
              </p>
              <p>
                Best pricing plan for you depends on if your primarily objective
                is in-budget delivery or high delivering apps. We recommend you
                to keep in-budget delivery as the objective if you are just
                starting the app or have limited time or knowledge to get into
                the nitty gritty of the deliveries. We would make sure we
                deliver in budget while keeping app's ROI to optimum.
              </p>
              <p>
                If you are looking for apps that deliver huge business outcomes
                then we recommend agile delivery model where we build the app by
                trial and iterations. There is reason why trail and iterations
                delivers high performing app, it's impossible to define and
                design the perfect app on paper, best way it to build a good
                enough app and validate, take feedback and improvise in further
                iteration. We cover you from budget standpoint by providing
                ballpark budget and are committed to get the delivery done well
                within this budget.
              </p>
            </div>

            <div className="col-lg-6 col-md-12">
              <img
                src={engagementBilling}
                className="animate__animated animate__zoomIn animate__delay-0.2s"
                alt="engagementBilling"
              />
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
      <Footer />
    </Layout>
  )
}

export default ServiceDetails

export const query = graphql`
  query webAppServices {
    caseStudies: allFile(
      filter: {
        sourceInstanceName: { eq: "caseStudies" }
        name: { eq: "index" }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            summary
            thumbImg {
              publicURL
            }
            thumbImgAlt
            summary
          }
          slug
        }
        sourceInstanceName
        relativeDirectory
        name
      }
    }
    testimonials: allFile(
      filter: {
        sourceInstanceName: { eq: "testimonials" }
        name: { eq: "index" }
        childMdx: {
          slug: {
            in: [
              "deepak-puthiyedath/"
              "roshan-radhakrishnan/"
              "amey-keni/"
              "amit-sanjeev/"
              "ramakrishnana-nutulapati/"
            ]
          }
        }
      }
    ) {
      nodes {
        childMdx {
          frontmatter {
            client
            reviewerDesignation
            title
            summary
            preview {
              publicURL
            }
            link
          }
        }
        relativeDirectory
        name
      }
    }
    awards: allFile(
      filter: {
        sourceInstanceName: { eq: "awards" }
        name: { eq: "index" }
        childMdx: {
          slug: {
            in: [
              "design-rush-accredited-agency/"
              "clutch-top-b2b-companies/"
              "design-rush-top-web-app-developers/"
              "pr-top-web-development-companies-june-2023/"
            ]
          }
        }
      }
      sort: { fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            name
            title
            order
            preview {
              publicURL
            }
          }
          slug
        }
        relativeDirectory
        name
      }
    }
    ourRecipeFeatures: allFile(
      filter: {
        sourceInstanceName: { eq: "ourRecipeFeatures" }
        name: { eq: "index" }
      }
      sort: { order: ASC, fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            key
            title
            thumbImg {
              publicURL
            }
            thumbImgAlt
            order
          }
          body
        }
      }
    }
    featuredCaseStudy: site {
      siteMetadata {
        webFeaturedCaseStudies
      }
    }
  }
`
